// components/layout.js

import { useFlag } from '@unleash/proxy-client-react';
import { Col, Row } from 'antd';
import Head from 'next/head';
import Image, { StaticImageData } from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { ReactNode, useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { ExclamationMarkSvg } from '../../assets/icons';
import LiveFrame from '../../assets/icons/LiveFrame';
import PlaySvg from '../../assets/icons/PlaySvg';
import theme from '../../assets/theme';
import { useIsMobile } from '../../hooks/useIsMobile';
import useSignIn from '../../hooks/useSignIn';
import { getBaseUrl } from '../../utils/client';
import { zIndexes } from '../../utils/zIndexes';
import { Button } from '../_atoms/Button';
import { IconWrapper } from '../_atoms/IconWrapper';
import MaxWidthContainer from '../_atoms/MaxWidthContainer';
import LandingPageFooter, { NewLandingPageFooter } from '../_molecules/LandingPageFooter';
import { LandingPageHeader } from '../_molecules/LandingPageHeader';
import LargeModal from '../_molecules/LargeModal';
import MyPresentationsButton from '../_organisms/MyPresentationsButton';
import HotjarSnippet from '../analytics/HotJar';
export const LandingPageLayout = ({
  children,
  title = 'Spark your students curiosity',
  subTitle = 'Join the growing movement of teachers using Curipod to make interactive lessons filled with creativity, reflection and critical thinking',
  primaryActionText,
  video,
  fullScreenVideo,
  image,
  hideActions,
  hideBlobs,
  mdx,
  disableHeadline,
  primaryActionLinkOverride,
  primaryActionLinkIsExternal = false,
  customHeader,
  imageUrl,
}: {
  children: ReactNode;
  title?: string;
  subTitle?: string;
  primaryActionText?: string;
  video?: string;
  fullScreenVideo?: string;
  image?: StaticImageData;
  imageUrl?: string;
  hideActions?: boolean;
  hideBlobs?: boolean;
  mdx?: boolean;
  disableHeadline?: boolean;
  primaryActionLinkOverride?: string;
  primaryActionLinkIsExternal?: boolean;
  customHeader?: ReactNode;
}) => {
  const isOutakes = useFlag('CURIPOD_OUTTAKES');

  const session = useSession();
  const router = useRouter();
  const { t } = useTranslation('Host');
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta property="og:title" content={title} key="title" />
        <meta name="twitter:title" content={title} />
        <meta name="description" content={subTitle} key="namedescription" />
        <meta property="description" content={subTitle} key="description" />
        <meta property="og:description" content={subTitle} key="og:description" />
        <meta name="twitter:description" content={subTitle} />
        {(image || imageUrl) && (
          <>
            <meta
              property="og:image"
              content={`${imageUrl ? imageUrl : `${getBaseUrl()}/${image?.src}`}`}
              key="imageurl"
            />
            <meta
              property="og:image:secure_url"
              content={`${imageUrl ? imageUrl : `${getBaseUrl()}/${image?.src}`}`}
              key="imagesecureurl"
            />
          </>
        )}
        <meta property="og:type" content="website" />

        <meta
          property="og:url"
          content={`${getBaseUrl()}${router.asPath || ''}`}
          key="ogurl"
        />
        {(image || imageUrl) && (
          <>
            <meta property="og:image:type" content={'image/jpeg'} key="imagetype" />
            <meta property="og:image:alt" content={subTitle} key="imageAlt" />
            <meta property="og:image:width" content={'300'} key="imageWidth" />
            <meta property="og:image:height" content={'190'} key="imageHeight" />
            <meta
              name="twitter:image"
              content={`${imageUrl ? imageUrl : `${getBaseUrl()}/${image?.src}`}`}
            />
          </>
        )}
        <meta name="twitter:site" content={'@curipodofficial'} />
        <meta name="twitter:card" content={'summary'} />
        <meta name="twitter:widgets:theme" content="light" />
        <meta name="twitter:url" content={`${getBaseUrl()}${router.asPath || ''}`} />
      </Head>
      <CookieConsent
        location="bottom"
        buttonText="Ok"
        cookieName="curipodcookieaccept"
        style={{ background: theme.colors.black, zIndex: 2247483003 }}
        buttonStyle={{
          color: theme.colors.white,
          backgroundColor: theme.colors.green,
          fontSize: '18px',
          borderRadius: 5,
          fontWeight: 'bold',
          width: 100,
        }}
        expires={150}
      >
        Curipod uses cookies to track website traffic and to provide a great customer
        support.
        <span style={{ marginLeft: 10 }}>
          <Link passHref href="/cookie-policy">
            <a style={{ color: theme.colors.white, textDecoration: 'underline' }}>
              See full cookie policy
            </a>
          </Link>
        </span>
      </CookieConsent>
      <LandingPageHeader hideBlobs={!!hideBlobs}>
        {isOutakes && (
          <MaintananceContainer>
            <span style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <IconWrapper height="30px" width="30px" style={{ marginRight: '10px' }}>
                <ExclamationMarkSvg />
              </IconWrapper>{' '}
              Curipod is currently having some problems. Sorry for the inconvenience.
              <IconWrapper height="30px" width="30px" style={{ marginRight: '10px' }}>
                <ExclamationMarkSvg />
              </IconWrapper>
            </span>
            <span style={{ width: '100%' }}>
              Follow the status here:{' '}
              <a href="https://curipod.statuspage.io/">Status page</a>
            </span>
          </MaintananceContainer>
        )}
        {customHeader && <>{customHeader}</>}
        {!disableHeadline && (
          <Headline
            title={title}
            image={image}
            subTitle={subTitle}
            secondaryActionText={session.status === 'unauthenticated' ? 'Log in' : ''}
            primaryActionText={
              primaryActionLinkOverride && primaryActionText
                ? primaryActionText
                : session.status === 'authenticated'
                ? t('My lessons')
                : primaryActionText
                ? primaryActionText
                : t('Get started for free')
            }
            primaryActionLinkOverride={primaryActionLinkOverride}
            primaryActionLinkIsExternal={primaryActionLinkIsExternal}
            hideActions={hideActions}
            video={video}
            fullScreenVideo={fullScreenVideo}
          />
        )}
      </LandingPageHeader>
      {mdx && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: theme.colors.background,
          }}
        >
          <MDContainer>{children}</MDContainer>
        </div>
      )}
      <HotjarSnippet />
      {!mdx && <main>{children}</main>}
      {router.pathname !== '/pricing' && <LandingPageFooter />}{' '}
      {/* FOR QA: Why do we have these two? */}
      {router.pathname === '/pricing' && (
        <NewLandingPageFooter footerBackgroundColor={theme.colors.paleBeige} />
      )}
    </>
  );
};

export default LandingPageLayout;

const Headline = ({
  image,
  title,
  subTitle,
  primaryActionText,
  secondaryActionText = '',
  hideActions = true,
  video,
  fullScreenVideo,
  primaryActionLinkOverride,
  primaryActionLinkIsExternal,
}: {
  title: string;
  subTitle: string;
  image?: StaticImageData;
  video?: string;
  primaryActionText: string;
  secondaryActionText?: string;
  hideActions?: boolean;
  fullScreenVideo?: string;
  primaryActionLinkOverride?: string;
  primaryActionLinkIsExternal?: boolean;
}) => {
  const ismobile = useIsMobile();
  const [showFullVideo, setShowFullVideo] = useState(false);
  const { signIn } = useSignIn();
  const session = useSession();

  return (
    <MaxWidthContainer>
      <HeadlineContainer $ismobile={ismobile}>
        <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
          <Left>
            <div>
              <h1
                style={{
                  fontSize: 36,
                  marginTop: 0,
                  color: theme.colors.white,
                  marginBottom: 50,
                }}
              >
                {title}
              </h1>
              <p
                style={{
                  fontWeight: 500,
                  color: theme.colors.white,
                  lineHeight: '30px',
                  fontSize: 18,
                  marginBottom: 50,
                }}
              >
                {subTitle}
              </p>

              {!hideActions && (
                <HeadlineActionSection>
                  {!primaryActionLinkOverride && (
                    <>
                      {session.status !== 'authenticated' && (
                        <Link href={'/signup'} passHref>
                          <a style={{ color: theme.colors.white, fontWeight: 'bold' }}>
                            <Button
                              type="primary"
                              styles={{
                                backgroundColor: theme.colors.orange,
                                borderRadius: '20px',
                              }}
                            >
                              {primaryActionText}
                            </Button>
                          </a>
                        </Link>
                      )}
                      {session.status === 'authenticated' && (
                        <MyPresentationsButton primaryActionText={primaryActionText} />
                      )}
                    </>
                  )}
                  {primaryActionLinkOverride && (
                    <Link href={primaryActionLinkOverride} passHref>
                      <a
                        target={primaryActionLinkIsExternal ? '_blank' : '_self'}
                        rel="noopener noreferrer"
                        style={{
                          color: theme.colors.white,
                          fontWeight: 'bold',
                          margin: '0 auto',
                        }}
                      >
                        <Button
                          type="primary"
                          styles={{
                            backgroundColor: theme.colors.orange,
                            borderRadius: '20px',
                          }}
                        >
                          {primaryActionText}
                        </Button>
                      </a>
                    </Link>
                  )}
                  {!primaryActionLinkIsExternal && (
                    <StyledLink
                      style={{
                        fontWeight: 500,
                        fontSize: 18,
                        color: 'white',
                        marginRight: 20,
                        cursor: 'pointer',
                      }}
                      onClick={() => signIn({ callbackUrl: '/' })}
                    >
                      {secondaryActionText}
                    </StyledLink>
                  )}
                </HeadlineActionSection>
              )}
            </div>
          </Left>
        </Col>
        <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
          <Right>
            {image && (
              <Image
                style={{ objectFit: 'contain' }}
                alt="the Curipod team"
                height={image.height}
                width={image.width}
                src={image.src}
              />
            )}
            {video && (
              <>
                {fullScreenVideo && (
                  <PlayButtonOverlay>
                    <LargeModal
                      onCancel={() => setShowFullVideo(false)}
                      onOk={() => setShowFullVideo(true)}
                      visible={showFullVideo}
                    >
                      <div
                        style={{
                          height: '100%',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <iframe
                          style={{ borderRadius: 10, marginBottom: 0 }}
                          width="100%"
                          height="100%"
                          src={fullScreenVideo}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </LargeModal>
                    <PlayButton
                      aria-label="Play the video in full screen"
                      onClick={() => setShowFullVideo(true)}
                    >
                      <PlaySvg />
                    </PlayButton>
                  </PlayButtonOverlay>
                )}
                <LiveFrameContainer $ismobile={ismobile}>
                  <LiveFrame />
                </LiveFrameContainer>
                <video
                  crossOrigin="anonymous"
                  preload="auto"
                  style={{
                    backgroundColor: 'transparent',
                    objectFit: 'contain',
                    height: ismobile ? '200px' : '300px',
                    maxWidth: ismobile ? 300 : '100%',
                    width: ismobile ? '100%' : 'unset',
                  }}
                  autoPlay
                  muted
                  loop
                >
                  <source src={video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </>
            )}
          </Right>
        </Col>
      </HeadlineContainer>
    </MaxWidthContainer>
  );
};

const Left = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 40px;
  padding-bottom: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Right = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlayButtonOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${zIndexes('LANDING_PAGE_VIDEO_PLAY_BUTTON_OVERLAY')};
`;

const PlayButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 30px;
    width: 30px;
  }
  border-radius: 100px;
  height: 70px;
  width: 70px;
  background-color: ${theme.colors.green};
  color: white;
  cursor: pointer;
  outline: none;
  border: none;
  &:hover {
    border: none;
    outline: none;
  }
`;

const HeadlineContainer = styled(Row)<{ $ismobile: boolean }>`
  align-items: center;
  @media (max-width: 800px) {
    padding-bottom: 40px;
  }
`;
const HeadlineActionSection = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 20px;
  }
`;

const LiveFrameContainer = styled.div<{ $ismobile?: boolean }>`
  position: absolute;
  height: 100%;
  width: 100%;
  svg {
    height: 100%;
    width: 100%;
  }

  ${({ $ismobile }) =>
    !!$ismobile &&
    css`
      max-width: 320px;
      max-height: 200px;
    `};
`;

const MDContainer = styled.main`
  max-width: 1000px;
  height: 100%;
  padding: 20px 0px;
  color: ${theme.colors.black};
  main {
    background-color: ${theme.colors.background};
  }
  margin-left: auto;
  margin-right: auto;

  img {
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }

  h1 {
    font-size: 60px;
    @media ${theme.device.mobileL} {
      font-size: 40px;
    }
  }
  h2 {
    font-size: 35px;
    margin-bottom: 40px;
  }
  h3 {
    font-size: 28px;
    margin-bottom: 40px;
  }

  p {
    font-size: 22px;
    line-height: 34px;
    font-weight: 450;
    /* margin-bottom: 40px; */
  }

  li {
    font-size: 22px;
    line-height: 34px;
    font-weight: 450;
  }

  a {
    font-weight: 450;
    font-size: 22px;
    color: #5e5e5e;
    transition: text-decoration 0.2s ease-in-out;
    text-decoration: underline;
    word-break: break-word;
  }

  th {
    text-align: left;
  }

  @media ${theme.device.laptop} {
    width: 680px;
  }
  @media ${theme.device.tablet} {
    width: 400px;
  }
  @media ${theme.device.mobileL} {
    width: 300px;
  }
`;
const StyledLink = styled.span`
  font-weight: 500;

  &:hover {
    color: black !important;
  }
`;

const MaintananceContainer = styled.div`
  width: 100%;
  gap: 10px;
  color: ${theme.colors.black};
  font-weight: bold;
  width: 100%;
  text-align: center;
  font-size: 24px;
  justify-content: center;
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  background-color: ${theme.colors.yellow};
  a {
    color: ${theme.colors.blue} !important;
    text-decoration: underline !important;
  }
`;
